<template>
  <picture v-lazy-load class="is-block">
    <source
      v-for="(source, index) in sources"
      :key="index"
      :data-srcset="source.srcset"
      :media="source.media"
    >
    <img :alt="alt" :title="title" :data-data-src="fallback">
  </picture>
</template>

<script setup lang="ts">
import type { ImageStyle } from '~/helpers'
import { buildRokkaUrl } from '~/helpers'
import { DPR, VIEWPORTS } from '~/rokka/settings'

const props = defineProps({
  imageStyle: {
    type: Object as PropType<ImageStyle>,
    required: false,
  },
  alt: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  rokkaHash: {
    type: String,
    required: false,
  },
  rokkaFilename: {
    type: String,
    required: false,
  },
})

const config = useRuntimeConfig()

const sources = computed(() => {
  return props.imageStyle.stacks
    .map((stack) => {
      const minWidth = VIEWPORTS[stack.viewport]
      const media
            = stack.viewport !== 'mobile' ? `(min-width: ${minWidth}px)` : ''
      const srcset = DPR.map((v) => {
        const url = buildRokkaUrl(
          stack.name,
          v,
          props.rokkaHash,
          props.rokkaFilename,
          config.public.rokkaOrg,
        )
        const pixelRatio = v || '1'

        return `${url} ${pixelRatio}x`
      }).join(', ')

      return { srcset, media, minWidth }
    })
    .sort((a, b) => {
      return b.minWidth - a.minWidth
    })
})

const fallback = computed(() => {
  const stack = props.imageStyle.stacks[0].name
  return buildRokkaUrl(
    stack,
    '',
    props.rokkaHash,
    props.rokkaFilename,
    config.public.rokkaOrg,
  )
})
</script>

<style lang="scss"></style>
