import type { Route } from 'vue-router'

export function hasOwnProperty(obj: any, property: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, property)
}

export function getDrupalPathFromRoute(route: Route) {
  return route.path
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined
}

export function sanitizeAttributeValue(str = '') {
  return str
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .trim()
    .replace(/\s+/g, ' ')
}

export function buildRokkaUrl(
  stack = '',
  dpr = '',
  hash = '',
  name = '',
  org = '',
  token = '',
) {
  const fileName = name.replace('.pdf', '.jpg')
  const options = dpr ? `/options-dpr-${dpr}` : ''
  return `https://${org}.rokka.io/${stack + options
  }/${hash}/${encodeURIComponent(fileName)}?h=${token}`
}

export interface EntityMetatags {
  key: string
  value: string
}

export interface Viewport {
  mobile: number
  tablet: number
  desktop: number
  widescreen: number
  fullhd: number
  fourk: number
}

export interface Stack {
  name: string
  viewport: string
  width: number
  height: number
}

export interface ImageStyle {
  name: string
  type: string
  stacks: Stack[]
  viewport?: Viewport
}
