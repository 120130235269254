<template>
  <img
    v-lazy-load
    :data-data-src="src"
    :data-data-srcset="srcset"
    :sizes="sizes"
    :alt="alt"
    :title="title"
    class="has-lazyloading"
  >
</template>

<script setup lang="ts">
import { DPR, VIEWPORTS } from '~/rokka/settings'
import type { ImageStyle, Stack } from '~/helpers'
import { buildRokkaUrl } from '~/helpers'

const props = defineProps({
  imageStyle: {
    type: Object as PropType<ImageStyle>,
    required: false,
  },
  alt: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  rokkaHash: {
    type: String,
    required: false,
  },
  rokkaFilename: {
    type: String,
    required: false,
  },
})

const config = useRuntimeConfig()

const images = computed(() => {
  return props.imageStyle.stacks
    .map((stack: Stack) => {
      return DPR.map((v) => {
        return {
          src: buildRokkaUrl(
            stack.name,
            v,
            props.rokkaHash,
            props.rokkaFilename,
            config.public.rokkaOrg,
          ),
          width: stack.width * (v ? 2 : 1),
        }
      })
    })
    .flat()
})

const sizes = computed(() => {
  if (!props.imageStyle) {
    return ''
  }

  const viewport = props.imageStyle.viewport

  return Object.keys(viewport)
    .map((key: string) => {
      const minWidth = VIEWPORTS[key] - 1
      // @ts-expect-error
      const width: number = viewport[key]

      return { width, minWidth }
    })
    .sort((a, b) => b.minWidth - a.minWidth)
    .map((v) => {
      return v.minWidth < 0
        ? `${v.width}px`
        : `(min-width: ${v.minWidth}px) ${v.width}px`
    })
})

const src = computed(() => {
  return images.value[0] ? images.value[0].src : ''
})

const srcset = computed(() => {
  return images.value
    .map((v) => {
      return `${v.src} ${v.width}w`
    })
    .join(', ')
})
</script>
