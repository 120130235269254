<template>
  <component
    :is="component"
    v-if="style && component"
    :image-style="style"
    :alt="alt"
    :title="title"
    :rokka-hash="image.rokkaHash"
    :rokka-filename="image.rokkaFilename"
  />
</template>

<script setup lang="ts">
import type { MediaImageFragment } from '#graphql-operations'
import imageStyles from '~/rokka/styles.json'
import type { ImageStyle } from '~/helpers'
import MediaImagePicture from '~/components/Media/Image/Picture/index.vue'
import MediaImageSizes from '~/components/Media/Image/Sizes/index.vue'

const props = defineProps({
  styleName: {
    type: String,
    required: false,
  },
  loading: {
    type: String,
    default: 'lazy',
  },
  image: {
    type: Object as PropType<MediaImageFragment['image']>,
    required: false,
  },
})

const style = computed((): ImageStyle => {
  // @ts-expect-error
  return imageStyles[props.styleName]
})

const component = computed(() => {
  if (!style.value || !props.image) {
    return
  }

  return style.value.type === 'picture'
    ? MediaImagePicture
    : MediaImageSizes
})

const alt = computed(() => props.image?.alt || '')
const title = computed(() => props.image?.title || '')
</script>

<style scoped lang="scss"></style>
